import { Container, Grid, Paper, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import type { FC, SyntheticEvent } from 'react';

import type {
  ApiForgotPasswordRequest,
  ApiForgotPasswordResponse,
} from 'api/password';
import { apiForgotPassword } from 'api/password';
import { ButtonWithSpinner } from 'shared/components/ButtonWithSpinner';
import Notification, { useNotification } from 'shared/components/Notification';
import { OrganisationLogo } from 'shared/components/OrganisationLogo';
import { useApi } from 'shared/hooks/useApi';
import { useSetTitle } from 'shared/hooks/useSetTitle';
import { isValidEmail } from 'shared/utils';

export const successEmailRequestText =
  'We have sent an email to reset you password to your registered email';
const recoverEmailText =
  "Need to change your password? We'll send a reset password email to your registered account";
export const noEmailError = 'Please, enter your email';
export const notValidEmailError = 'Please, enter a valid email address';
export const recoverButtonText = 'Send reset password email';

const RecoverPassword: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [emailValidationError, setEmailValidationError] = useState('');

  const { isLoading, error, setError, data, callApi } = useApi<
    ApiForgotPasswordRequest,
    ApiForgotPasswordResponse
  >(apiForgotPassword);

  const { notification, severity, setNotificationError, onNotificationClose } =
    useNotification();

  useSetTitle({ title: 'Recover password' });

  useEffect(() => {
    if (error) {
      setNotificationError(error);
      setError('');
    }
  }, [error, setError, setNotificationError]);

  const validateEmail = () => {
    if (!email) {
      setEmailValidationError(noEmailError);
      return false;
    }
    if (!isValidEmail(email)) {
      setEmailValidationError(notValidEmailError);
      return false;
    }
    setEmailValidationError('');
    return true;
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (validateEmail()) {
      callApi({ email });
    }
  };

  return (
    <>
      <Container fixed maxWidth="xs">
        <Grid
          container
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: '90vh',
            transition: 'all 300ms ease',
          }}
        >
          <Paper
            elevation={4}
            sx={{
              padding: 3,
            }}
          >
            <OrganisationLogo />
            {data && !error ? (
              <>
                <Typography sx={{ marginBottom: 2 }} variant="h6" gutterBottom>
                  {successEmailRequestText}
                </Typography>
              </>
            ) : (
              <form onSubmit={handleSubmit}>
                <Typography sx={{ marginBottom: 2 }} variant="h6" gutterBottom>
                  {recoverEmailText}
                </Typography>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    sx={{
                      paddingTop: 1,
                      marginBottom: 2,
                    }}
                    error={!!emailValidationError}
                    fullWidth
                    helperText={emailValidationError}
                    id="email"
                    label="Email"
                    margin="dense"
                    onChange={(e) => setEmail(e.target.value)}
                    type="text"
                    value={email}
                  />
                </Grid>
                <Grid container item xs={12} justifyContent="flex-end">
                  <ButtonWithSpinner
                    color="primary"
                    loading={isLoading}
                    type="submit"
                    sx={{ marginTop: 2, marginBottom: 0 }}
                    variant="contained"
                  >
                    {recoverButtonText}
                  </ButtonWithSpinner>
                </Grid>
              </form>
            )}
          </Paper>
        </Grid>
      </Container>
      <Notification
        message={notification}
        onClose={onNotificationClose}
        open={!!notification}
        severity={severity}
      />
    </>
  );
};

export default RecoverPassword;
