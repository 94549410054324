import { useTheme } from '@mui/material';
import type { FC } from 'react';
import { memo } from 'react';

import { appConfig } from 'shared/config';

export const OrganisationLogo: FC = memo(function OrganisationLogo() {
  const theme = useTheme();
  return (
    <img
      src={appConfig.organisationLogo()}
      alt="Organisation Logo"
      style={{
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(2),
        height: 48,
      }}
    />
  );
});
