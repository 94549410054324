/**
 * Unwraps Promise return value
 * e.g. `Promise<{ a: number; }>` => `{ a: number; }`
 */
export type PromiseReturnType<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: never) => Promise<infer U>
  ? U
  : T extends (...args: never) => infer U
  ? U
  : T;

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
}

export enum ApiResponseRequestVariant {
  Lean = 'lean',
  Default = 'default',
  Web = 'web',
  Full = 'full',
  I18n = 'i18n',
}

export enum Locale {
  EnUS = 'en-US',
  EnGB = 'en-GB',
  EnAU = 'en-AU',
}

export interface SuccessResponse<T> {
  data: T;
  ok: true;
}

export interface ApiAcknowledgeResponse {
  acknowledged: boolean;
}

export interface AcknowledgedSuccessResponse {
  ok: true;
  data: ApiAcknowledgeResponse;
}

export enum ErrorLevel {
  Warning = 'warning',
  Error = 'error',
}

export interface ApiErrorPayload {
  code?: number;
  name?: string;
  message?: string;
  error?: string;
  type?: string;
  errorDescription?: string;
}

export type ApiResponse<TResponse> = SuccessResponse<TResponse> | ErrorResponse;

export interface ErrorDetails {
  details: {
    code?: number;
    name?: string;
    level?: ErrorLevel;
    message: string;
  };
}

export interface ErrorResponse extends ErrorDetails {
  ok: false;
}

export interface ApiAdditionalInfo {
  url: string;
  method: HttpMethod;
  body: URLSearchParams | unknown;
}

export type ApiRequestFn<TResponse> = Promise<ApiResponse<TResponse>>;
