import { Container, Grid, Paper, Typography } from '@mui/material';
import type { FC } from 'react';

import { useSetTitle } from 'shared/hooks/useSetTitle';

import { ReactComponent as NotFoundImage } from './no-data.svg';

export const textNotFoundTitle = 'Something is missing';
export const textNotFoundText =
  "We can't seem to find what you're looking for. Are you sure the URL is correct?";
export const titleNotFound = 'Not found';

const NotFound: FC = () => {
  useSetTitle({ title: titleNotFound });

  return (
    <Container maxWidth="lg" sx={{ boxSizing: 'content-box', p: 0 }}>
      <Grid
        container
        boxSizing="border-box"
        justifyContent="center"
        alignItems="left"
        direction="column"
        sx={{
          minHeight: '90vh',
        }}
      >
        <Paper elevation={4} sx={{ width: '100%', py: 4 }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={6} sx={{ textAlign: 'center' }}>
              <NotFoundImage />
            </Grid>
            <Grid item xs={12} sm={6} px={4}>
              <Grid container alignContent="center" pl={4}>
                <Typography variant="h4" mb={4}>
                  {textNotFoundTitle}
                </Typography>
                <Typography variant="body1" mb={4}>
                  {textNotFoundText}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Container>
  );
};

export default NotFound;
