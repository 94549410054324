import { datadogLogs } from '@datadog/browser-logs';
import * as Sentry from '@sentry/react';
import type { AxiosResponse } from 'axios';

import type { ErrorResponse, ErrorDetails, HttpMethod } from 'api/types';
import { ErrorLevel } from 'api/types';
import { appConfig } from 'shared/config';

export const tryParseJson = <T>(responseText: string): T | undefined => {
  try {
    return JSON.parse(responseText);
  } catch {
    return undefined;
  }
};

interface HandleErrorDetails extends ErrorDetails {
  url: string;
  method: HttpMethod;
  body: URLSearchParams | unknown;
  response: Response | AxiosResponse | undefined;
  httpStatus?: number;
}

export const handleError = (
  errorDetails: HandleErrorDetails,
  responseText?: string
): ErrorResponse => {
  const { url, method, response, body } = errorDetails;

  const sentryExtraDetails = {
    errorDetails,
    url,
    method,
    responseStatusText: response?.statusText,
    responseStatus: response?.status,
    responseText,
  };
  const dataDogDetails = {
    ...sentryExtraDetails,
    env: appConfig.environment(),
    requestBody: body,
  };

  if (errorDetails.details.level === ErrorLevel.Warning) {
    datadogLogs.logger.warn(`Request error ${url}`, {
      ...dataDogDetails,
    });

    Sentry.captureMessage(`HTTP request error ${url}`, {
      extra: sentryExtraDetails,
      level: Sentry.Severity.Warning,
    });

    return {
      details: { ...errorDetails.details },
      ok: false,
    };
  }

  datadogLogs.logger.error(`Request error ${url}`, {
    ...dataDogDetails,
  });

  Sentry.captureMessage(`HTTP request error ${url}`, {
    extra: sentryExtraDetails,
    level: Sentry.Severity.Error,
  });

  return {
    details: errorDetails.details,
    ok: false,
  };
};

export const getErrorLogLevel = (code: number | undefined) =>
  // https://docs.kitchenos.com/docs/api-concepts#errors
  // http status, code, reason
  // 401, 601, 'Missing token'
  // 401, 602, 'Invalid token'
  // 401, 603, 'Expired token'
  // 401, 604, 'Revoked token'
  code && code > 600 && code < 605 ? ErrorLevel.Warning : ErrorLevel.Error;

export const getErrorString = (e: unknown): string =>
  (e as Error).message || String(e);
