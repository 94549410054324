import { appConfig } from 'shared/config';

import { fetchJson } from './fetchJson';
import type { ApiAcknowledgeResponse } from './types';
import { HttpMethod } from './types';

export const postUnsubscribeEndpoint = '/users/unsubscribe';

export interface ApiUnsubscribeRequest {
  token: string;
}

export const apiUnsubscribe = ({ token }: ApiUnsubscribeRequest) => {
  return fetchJson<ApiAcknowledgeResponse>({
    url: `${appConfig.apiUrl()}${postUnsubscribeEndpoint}/${token}`,
    httpMethod: HttpMethod.Post,
  });
};
