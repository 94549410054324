import {
  organisationNames,
  organisationLogos,
  Organisation,
} from 'shared/constants';

import appPackageJson from '../../package.json';

export enum AppEnvironment {
  Development = 'development',
  Production = 'production',
  Staging = 'staging',
  Test = 'test',
}

const envVarsGetters = {
  /*  eslint-disable @typescript-eslint/no-non-null-assertion */
  environment: (): AppEnvironment =>
    process.env.REACT_APP_ENV! as AppEnvironment,
  platformApiToken: (): string => process.env.REACT_APP_PLATFORM_API_TOKEN!,
  platformApiUrl: (): string => process.env.REACT_APP_PLATFORM_API_URL!,
  /* eslint-enable @typescript-eslint/no-non-null-assertion */
};

const isEnv = (...args: AppEnvironment[]) => {
  const environment = envVarsGetters.environment();
  return args.includes(environment);
};

const isDevOrTestEnv = () =>
  isEnv(AppEnvironment.Development, AppEnvironment.Test);

const organisation = (): Organisation =>
  (new RegExp(`${Object.values(Organisation).join('|')}`).exec(
    window.location.hostname
  )?.[0] as Organisation) ?? Organisation.Drop;

const organisationName = (): string => organisationNames[organisation()];
const organisationLogo = (): string => organisationLogos[organisation()];

const version = (): string => appPackageJson.version;

const frescoApi = (): string =>
  isDevOrTestEnv() ? '' : envVarsGetters.platformApiUrl();

const apiUrl = (): string => `${frescoApi()}/api/v1`;

export const appConfig = {
  ...envVarsGetters,
  version,
  organisation,
  organisationName,
  organisationLogo,
  apiUrl,
};
