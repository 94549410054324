import { Locale } from 'api/types';
import FrescoLogo from 'shared/logos/fresco-logo.svg';
import InstantBrandsLogo from 'shared/logos/instantbrands-logo.svg';
import KenwoodLogo from 'shared/logos/kenwood-logo.svg';

export enum Organisation {
  Drop = 'drop',
  Kenwood = 'kenwood',
  InstantBrands = 'instantbrands',
}

export const organisationNames: Record<Organisation, string> = {
  [Organisation.Drop]: 'Fresco',
  [Organisation.Kenwood]: 'Kenwood',
  [Organisation.InstantBrands]: 'Instant Brands',
};

export const organisationLogos: Record<Organisation, string> = {
  [Organisation.Drop]: FrescoLogo,
  [Organisation.Kenwood]: KenwoodLogo,
  [Organisation.InstantBrands]: InstantBrandsLogo,
};

export const acceptHeader = 'application/vnd.drop.v4';
export const defaultLocale = Locale.EnUS;

export const sentryDsn =
  'https://fb1d342ba38a4032af51497f8e93bae2@o761960.ingest.sentry.io/6080595';
export const datadogLogsClientToken = 'pub261547b02ad096f496cb59b02d578b56';
