import { Alert, Snackbar } from '@mui/material';
import type { FC, SyntheticEvent } from 'react';
import { useState } from 'react';

export enum NotificationSeverity {
  Error = 'error',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

export interface NotificationProps {
  message: string;
  onClose: () => void;
  open: boolean;
  severity: NotificationSeverity;
}

export const useNotification = () => {
  const [notification, setNotification] = useState<string>('');
  const [severity, setSeverity] = useState<NotificationSeverity>(
    NotificationSeverity.Error
  );

  const onNotificationClose = () => setNotification('');

  const setNotificationError = (message: string) => {
    setNotification(message);
    setSeverity(NotificationSeverity.Error);
  };

  return {
    notification,
    severity,
    setNotificationError,
    onNotificationClose,
  };
};

const Notification: FC<NotificationProps> = ({
  message,
  onClose,
  open = false,
  severity,
}) => {
  const handleCloseNotification = (
    _event?: SyntheticEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      onClose={handleCloseNotification}
      open={open}
    >
      <Alert
        elevation={6}
        onClose={handleCloseNotification}
        severity={severity}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
