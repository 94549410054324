import type { PaletteOptions } from '@mui/material';
import { createTheme } from '@mui/material/styles';

const frescoPallete: PaletteOptions = {
  primary: {
    main: '#75083A',
    contrastText: '#FEF9EE',
    light: '#F6B7D1',
    dark: '#3F1026',
  },
  secondary: {
    main: '#75083A',
    light: '#F6B7D1',
    dark: '#3F1026',
  },
  divider: 'rgba(0,0,0,0.1)',
  background: {
    default: '#FEF9EE',
    paper: '#FFFFFF',
  },
  success: {
    main: '#44af69',
    contrastText: '#1f1d1a',
  },
  error: {
    main: '#FC4545',
    contrastText: '#1F1D1A',
  },
  info: {
    main: '#75083a',
  },
  text: {
    secondary: 'rgba(0,0,0,0.6)',
    primary: '#1F1D1A',
  },
};

export const appTheme = createTheme({
  // spacing: 5,
  palette: frescoPallete,
  typography: {
    fontFamily: 'GTWalsheim-Md',
    h1: {
      fontSize: '96px',
      lineHeight: '112px',
      letterSpacing: '-1.5px',
    },
    h2: {
      fontSize: '60px',
      lineHeight: '72',
      letterSpacing: '-0.5px',
    },
    h3: {
      fontSize: '48px',
      lineHeight: '56px',
      letterSpacing: 0,
    },
    h4: {
      fontSize: '34px',
      lineHeight: '36px',
      letterSpacing: '0.25px',
    },
    h5: {
      fontSize: '24px',
      lineHeight: '24px',
      letterSpacing: 0,
    },
    h6: {
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    subtitle1: {
      fontFamily: 'DMSans-Bold',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
    },
    subtitle2: {
      fontFamily: 'DMSans-Bold',
      fontSize: '14px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
    },
    body1: {
      fontFamily: 'DMSans-Regular',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    body2: {
      fontFamily: 'DMSans-Regular',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    caption: {
      fontFamily: 'DMSans-Regular',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
    overline: {
      fontFamily: 'DMSans-Bold',
      fontSize: '10px',
      lineHeight: '16px',
      letterSpacing: '1.5px',
    },
    button: {
      fontFamily: 'DMSans-Bold',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      textTransform: 'none',
    },
  },
});
