import { ThemeProvider } from '@mui/material/styles';
import type { FC } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from 'app/Layout';
import { appTheme } from 'app/theme';
import NotFound from 'features/notFound/NotFound';
import RecoverPassword from 'features/recoverPassword/RecoverPassword';
import ResetPassword from 'features/resetPassword/ResetPassword';
import Unsubscribe from 'features/unsubscribe/Unsubscribe';

const App: FC = () => {
  return (
    <ThemeProvider theme={appTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Layout />}>
            <Route path="recover-password" element={<RecoverPassword />} />
            <Route path="reset-password/:token" element={<ResetPassword />} />
            <Route path="unsubscribe/:token" element={<Unsubscribe />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
