import { datadogLogs } from '@datadog/browser-logs';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';

import App from 'app/App';
import './index.css';
import { appConfig, AppEnvironment } from 'shared/config';
import { datadogLogsClientToken, sentryDsn } from 'shared/constants';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import config from './aws-exports';

Amplify.configure(config);

if (appConfig.environment() !== AppEnvironment.Development) {
  datadogLogs.onReady(() => {
    datadogLogs.init({
      clientToken: datadogLogsClientToken,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sampleRate: 100,
      service: 'account',
      silentMultipleInit: true,
      env: appConfig.environment(),
      version: appConfig.version(),
    });
  });
}

Sentry.init({
  dsn: sentryDsn,
  enabled: appConfig.environment() !== AppEnvironment.Development,
  environment: appConfig.environment(),
  integrations: [new Integrations.BrowserTracing()],
  release: appConfig.version(),
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
