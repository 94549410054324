import { awsApiPost, fetchJson } from 'api/fetchJson';
import { HttpMethod } from 'api/types';
import type { ApiAcknowledgeResponse, ApiRequestFn } from 'api/types';
import { appConfig } from 'shared/config';

export const forgotPasswordApiName = 'accountApiForgotPassword';
export const forgotPasswordEndpoint = '/forgot-password';
export const resetPasswordEndpoint = '/users/change-password';

export interface ApiForgotPasswordRequest {
  email: string;
}

export interface ApiForgotPasswordResponse extends ApiAcknowledgeResponse {
  message: string;
}

export const apiForgotPassword = ({
  email,
}: ApiForgotPasswordRequest): ApiRequestFn<ApiForgotPasswordResponse> => {
  const org = appConfig.organisation();
  const body = { email, org };

  return awsApiPost<ApiForgotPasswordResponse>({
    apiName: 'accountApiForgotPassword',
    path: forgotPasswordEndpoint,
    body,
  });
};

export interface ApiResetPasswordRequest {
  password: string;
  token: string;
}

export const apiResetPassword = ({
  password,
  token,
}: ApiResetPasswordRequest) => {
  const body = { password };
  return fetchJson<ApiAcknowledgeResponse>({
    url: `${appConfig.apiUrl()}${resetPasswordEndpoint}/${token}`,
    body,
    httpMethod: HttpMethod.Post,
  });
};
