export const isValidEmail = (email: string): boolean => {
  const re =
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const isValidPassword = (password: string): boolean => {
  // Minimum of 8 characters long
  return password.length >= 8;
};

export const getErrorString = (e: unknown): string =>
  (e as Error).message || String(e);
