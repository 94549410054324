import {
  Alert,
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import { useEffect, useState, memo } from 'react';
import { useParams } from 'react-router-dom';

import { apiUnsubscribe } from 'api/unsubscribe';
import { OrganisationLogo } from 'shared/components/OrganisationLogo';
import { useSetTitle } from 'shared/hooks/useSetTitle';

export const textUnsubscribeError = 'An error has occurred: ';

const Unsubscribe: FC = () => {
  const [error, setError] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(true);
  const [unsubscribed, setUnsubscribed] = useState<boolean>(false);

  const { token } = useParams<'token'>();

  useSetTitle({ title: 'Unsubscribe' });

  useEffect(() => {
    const unsubscribe = async () => {
      setError('');
      setSubmitting(true);
      // It will always have a token because if not, the router will redirect to not found page
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const res = await apiUnsubscribe({ token: token! });
      setSubmitting(false);
      if (!res.ok) {
        setError(res.details.message);
      } else {
        setUnsubscribed(true);
      }
    };

    unsubscribe();
  }, [token, setError]);

  return (
    <Container fixed maxWidth="xs">
      <Grid
        container
        sx={{
          flexDirection: 'column',
          justifyContent: 'center',
          minHeight: '90vh',
          transition: 'all 300ms ease',
        }}
      >
        <Paper
          elevation={4}
          sx={{
            padding: 3,
          }}
        >
          <OrganisationLogo />
          <Grid container>
            {submitting && (
              <Grid container justifyContent="center">
                <CircularProgress color="primary" />
              </Grid>
            )}
            {unsubscribed && <UnsubscribeSuccess />}
            {error && <UnsubscribeError error={error} />}
          </Grid>
        </Paper>
      </Grid>
    </Container>
  );
};

const UnsubscribeSuccess: FC = memo(function UnsubscribeSuccess() {
  return (
    <>
      <Typography
        variant="h6"
        sx={{
          paddingBottom: 1.5,
          paddingTop: 1.5,
        }}
      >
        You have been unsubscribed
      </Typography>
      <Typography variant="body1" mt={2}>
        We hate to see you go, but hope to meet again. Take care of yourself and
        best of luck in the kitchen!
      </Typography>
    </>
  );
});

interface UnsubscribeErrorProps {
  error: string;
}

const UnsubscribeError: FC<UnsubscribeErrorProps> = memo(
  function UnsubscribeError({ error }: UnsubscribeErrorProps) {
    return error ? (
      <Alert severity="error">
        {textUnsubscribeError}
        {error}
      </Alert>
    ) : null;
  }
);

export default Unsubscribe;
