import type { FC } from 'react';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { appConfig } from 'shared/config';

const Layout: FC = () => {
  const faviconUpdate = async () => {
    const favicon = document.getElementById('favicon') as HTMLLinkElement;
    if (favicon) {
      favicon.href = `/${appConfig.organisation()}-favicon.ico`;
    }
  };

  useEffect(() => {
    faviconUpdate();
  }, []);

  return <Outlet />;
};

export default Layout;
