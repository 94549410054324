import { useState } from 'react';

import { getErrorString } from 'api/common';
import type { ApiRequestFn } from 'api/types';

export const useApi = <TRequest, TResponse>(
  apiCall: (body: TRequest) => ApiRequestFn<TResponse>
) => {
  const [data, setData] = useState<TResponse>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const callApi = async (body: TRequest) => {
    setError('');
    setIsLoading(true);

    try {
      const response = await apiCall({ ...body });
      if (!response.ok) {
        setError(response.details.message);
      } else {
        setData(response.data);
      }
    } catch (e) {
      setError(getErrorString(e));
    }

    setIsLoading(false);
  };

  return { data, isLoading, error, setError, callApi };
};
