import { datadogLogs } from '@datadog/browser-logs';
import { API } from 'aws-amplify';
import type { AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';

import { tryParseJson, getErrorLogLevel, handleError } from 'api/common';
import type {
  ApiResponseRequestVariant,
  ApiResponse,
  Locale,
  ApiErrorPayload,
} from 'api/types';
import { ErrorLevel, HttpMethod } from 'api/types';
import { appConfig } from 'shared/config';
import { acceptHeader, defaultLocale } from 'shared/constants';
import { getErrorString } from 'shared/utils';

type MergeDataFromHeadersMap<T> = {
  [propertyName in keyof T]: string;
};

export interface FetchJsonParams<T> {
  locale?: Locale;
  url: string;
  body?: URLSearchParams | unknown;
  httpMethod?: HttpMethod;
  responseVariant?: ApiResponseRequestVariant;
  requestVariant?: ApiResponseRequestVariant;
  mergeDataFromHeadersMap?: MergeDataFromHeadersMap<T>;
  signal?: AbortSignal;
}

/**
 * Sends HTTP request with `Accept: application/json`
 *
 * - if `body` is not passed - sends GET HTTP
 * - if `body` is `URLSearchParams` - sends POST with `Content-Type: application/x-www-form-urlencoded`
 * - if `body` is `FormData` - sends POST with `Content-Type: multipart/form-data`
 * - otherwise sends POST as `JSON.stringify(body)` with `Content-Type: application/json`
 */
export const fetchJson = async <TResponse>({
  locale,
  url,
  body,
  httpMethod,
  responseVariant,
  requestVariant,
  mergeDataFromHeadersMap,
  signal,
}: FetchJsonParams<TResponse>): Promise<ApiResponse<TResponse>> => {
  let response: Response | undefined;
  const method = httpMethod || (body ? HttpMethod.Post : HttpMethod.Get);
  const requestContentType = requestVariant
    ? `${acceptHeader}.${requestVariant}+json`
    : getContentType(body);

  try {
    response = await fetch(url, {
      method,
      body: getBody(body),
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Accept: responseVariant
          ? `${acceptHeader}.${responseVariant}+json`
          : 'application/json',
        'Accept-Language': locale || defaultLocale,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${appConfig.platformApiToken()}`,
        ...(requestContentType && {
          'Content-Type': requestContentType,
        }),
      },
      signal,
    });
  } catch (e) {
    return handleError({
      details: {
        message: getErrorString(e),
        level: ErrorLevel.Error,
      },
      url,
      method,
      body,
      response,
    });
  }

  const responseText = await response.text();

  if (!response.ok) {
    const responseJson = tryParseJson<ApiErrorPayload>(responseText);
    const errorData = responseJson
      ? camelcaseKeys(responseJson)
      : ({} as ApiErrorPayload);
    return handleError(
      {
        httpStatus: response.status,
        details: {
          code: response.status,
          ...errorData,
          message:
            errorData?.message ||
            errorData?.errorDescription ||
            errorData?.error ||
            'Something went wrong',
          level: getErrorLogLevel(errorData?.code),
        },
        url,
        method,
        body,
        response,
      },
      responseText
    );
  }

  const responseJson = tryParseJson<TResponse>(responseText);
  let data = responseJson
    ? (camelcaseKeys(responseJson, {
        deep: true,
      }) as TResponse)
    : ({} as TResponse);
  if (mergeDataFromHeadersMap) {
    data = {
      ...data,
      ...getDataFromHeaders(response.headers, mergeDataFromHeadersMap),
    };
  }
  datadogLogs.logger.info(`Request success ${url}`, {
    url,
    method,
    responseStatusText: getErrorString(response),
    responseStatus: response.status,
    env: appConfig.environment(),
  });

  return {
    data,
    ok: true,
  };
};

export interface AwsApiPostParams {
  apiName: string;
  path: string;
  body: URLSearchParams | unknown;
}

export const awsApiPost = async <TResponse>({
  apiName,
  path,
  body,
}: AwsApiPostParams): Promise<ApiResponse<TResponse>> => {
  let response: AxiosResponse | undefined;
  try {
    response = await API.post(apiName, path, { response: true, body });
  } catch (e) {
    return handleError({
      details: {
        message: getErrorString(e),
        level: ErrorLevel.Error,
      },
      url: path,
      method: HttpMethod.Post,
      body,
      response,
    });
  }

  if (response && response.status !== 200) {
    // Payload returned from Platform through AWS Lambda will always be json
    const errorData = response.data
      ? (camelcaseKeys(response.data) as ApiErrorPayload)
      : ({} as ApiErrorPayload);
    return handleError({
      httpStatus: response.status,
      details: {
        code: response.status,
        message:
          errorData?.message ||
          errorData?.errorDescription ||
          errorData?.error ||
          'Something went wrong',
        level: getErrorLogLevel(response.status),
      },
      url: path,
      method: HttpMethod.Post,
      body,
      response,
    });
  }

  const data = response?.data
    ? (camelcaseKeys(response.data, {
        deep: true,
      }) as TResponse)
    : ({} as TResponse);
  datadogLogs.logger.info(`Request success ${path}`, {
    url: path,
    method: HttpMethod.Post,
    env: appConfig.environment(),
  });

  return {
    data,
    ok: true,
  };
};

function getBody(body: unknown): string | URLSearchParams | FormData {
  return body instanceof URLSearchParams || body instanceof FormData
    ? body
    : JSON.stringify(body);
}

function getDataFromHeaders<T extends { [key: string]: unknown }>(
  headers: Headers,
  mergeDataFromHeadersMap: MergeDataFromHeadersMap<T>
): { [key in keyof T]: string | null } {
  const data = {} as { [key in keyof T]: string | null };
  for (const key of Object.keys(mergeDataFromHeadersMap)) {
    data[key as keyof T] = headers.get(mergeDataFromHeadersMap[key]);
  }
  return data;
}

function getContentType(body: unknown): string | undefined {
  if (
    body &&
    !(body instanceof URLSearchParams) &&
    !(body instanceof FormData)
  ) {
    return 'application/json';
  }
  return undefined;
}
