import { Container, Grid, Paper, TextField, Typography } from '@mui/material';
import type { FC, SyntheticEvent } from 'react';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import type { ApiResetPasswordRequest } from 'api/password';
import { apiResetPassword } from 'api/password';
import type { ApiAcknowledgeResponse } from 'api/types';
import { ButtonWithSpinner } from 'shared/components/ButtonWithSpinner';
import Notification, { useNotification } from 'shared/components/Notification';
import { OrganisationLogo } from 'shared/components/OrganisationLogo';
import { useApi } from 'shared/hooks/useApi';
import { useSetTitle } from 'shared/hooks/useSetTitle';
import { isValidPassword } from 'shared/utils';

export const textResetPassword = 'Please, enter your new password';
export const textResetButton = 'Reset password';
export const textNoPasswordsError = 'Please, enter a valid password';
export const textPasswordsNotMatchingError = 'Please, ensure passwords match';
export const textPasswordValidationError =
  'Your new password must be a minimum of 8 characters long';
export const textResetPasswordSuccess =
  'Congratulations! Your password has been updated.';

const ResetPassword: FC = () => {
  const { token } = useParams<'token'>();

  const [error, setError] = useState<string>('');
  const [password1, setPassword1] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');

  const {
    callApi,
    data,
    error: apiError,
    setError: setApiError,
    isLoading,
  } = useApi<ApiResetPasswordRequest, ApiAcknowledgeResponse>(apiResetPassword);

  const { notification, severity, setNotificationError, onNotificationClose } =
    useNotification();

  useSetTitle({ title: 'Reset Password' });

  useEffect(() => {
    if (apiError) {
      setNotificationError(apiError);
      setApiError('');
    }
  }, [apiError, setApiError, setNotificationError]);

  const handleSubmit = (e: SyntheticEvent) => {
    setError('');
    e.preventDefault();
    if (!password1 || !password2) {
      setError(textNoPasswordsError);
      return false;
    }
    if (password1 !== password2) {
      setError(textPasswordsNotMatchingError);
      return false;
    }
    if (!isValidPassword(password1)) {
      setError(textPasswordValidationError);
      return false;
    }
    // It will always have a token because if not, the router will redirect to not found page
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    callApi({ password: password1, token: token! });
  };

  return (
    <>
      <Container fixed maxWidth="xs">
        <Grid
          container
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            minHeight: '90vh',
            transition: 'all 300ms ease',
          }}
        >
          <Paper
            elevation={4}
            sx={{
              padding: 3,
            }}
          >
            <OrganisationLogo />
            {data && !error ? (
              <Typography sx={{ marginBottom: 2 }} variant="h6" gutterBottom>
                {textResetPasswordSuccess}
              </Typography>
            ) : (
              <form onSubmit={handleSubmit}>
                <Typography sx={{ marginBottom: 2 }} variant="h6" gutterBottom>
                  {textResetPassword}
                </Typography>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    sx={{
                      paddingTop: 1,
                      marginBottom: 2,
                    }}
                    error={!!error}
                    fullWidth
                    id="password1"
                    label="New password"
                    margin="dense"
                    onChange={(e) => setPassword1(e.target.value)}
                    type="password"
                    value={password1}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    sx={{
                      paddingTop: 1,
                      marginBottom: 2,
                    }}
                    error={!!error}
                    fullWidth
                    helperText={error}
                    id="password2"
                    label="Confirm new password"
                    margin="dense"
                    onChange={(e) => setPassword2(e.target.value)}
                    type="password"
                    value={password2}
                  />
                </Grid>
                <Grid container item xs={12} justifyContent="flex-end">
                  <ButtonWithSpinner
                    color="primary"
                    loading={isLoading}
                    type="submit"
                    sx={{ marginTop: 2, marginBottom: 0 }}
                    variant="contained"
                  >
                    {textResetButton}
                  </ButtonWithSpinner>
                </Grid>
              </form>
            )}
          </Paper>
        </Grid>
      </Container>
      <Notification
        message={notification}
        onClose={onNotificationClose}
        open={!!notification}
        severity={severity}
      />
    </>
  );
};

export default ResetPassword;
