import { Tooltip } from '@mui/material';
import type { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import MuiButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { memo } from 'react';
import type { FC } from 'react';

export type ButtonType = 'button' | 'submit';

export interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
  tooltip?: React.ReactNode;
  type?: ButtonType;
}

export const ButtonWithSpinner: FC<ButtonProps> = memo(
  function ButtonWithSpinner({
    loading,
    type = 'button',
    variant = 'contained',
    startIcon,
    tooltip,
    ...restProps
  }: ButtonProps) {
    return (
      <Tooltip title={(loading ? '' : tooltip) || ''}>
        <MuiButton
          {...restProps}
          disabled={loading}
          variant={variant}
          type={type}
          startIcon={
            loading ? (
              <CircularProgress
                sx={{ marginLeft: 1 }}
                size={24}
                color="inherit"
              />
            ) : (
              startIcon
            )
          }
        />
      </Tooltip>
    );
  }
);
